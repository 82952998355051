@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 100;

  src:
    local("Pretendard Thin"),
    local("Pretendard-Thin"),
    url("./fonts/Pretendard-Thin.woff2") format("woff2"),
    url("./fonts/Pretendard-Thin.woff") format("woff"),
    url("./fonts/Pretendard-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 200;

  src:
    local("Pretendard ExtraLight"),
    local("Pretendard-ExtraLight"),
    url("./fonts/Pretendard-ExtraLight.woff2") format("woff2"),
    url("./fonts/Pretendard-ExtraLight.woff") format("woff"),
    url("./fonts/Pretendard-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 300;
  src:
    local("Pretendard Light"),
    local("Pretendard-Light"),
    url("./fonts/Pretendard-Light.woff2") format("woff2"),
    url("./fonts/Pretendard-Light.woff") format("woff"),
    url("./fonts/Pretendard-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  src:
    local("Pretendard Regular"),
    local("Pretendard-Regular"),
    url("./fonts/Pretendard-Regular.woff2") format("woff2"),
    url("./fonts/Pretendard-Regular.woff") format("woff"),
    url("./fonts/Pretendard-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  src:
    local("Pretendard Medium"),
    local("Pretendard-Medium"),
    url("./fonts/Pretendard-Medium.woff2") format("woff2"),
    url("./fonts/Pretendard-Medium.woff") format("woff"),
    url("./fonts/Pretendard-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  src:
    local("Pretendard SemiBold"),
    local("Pretendard-SemiBold"),
    url("./fonts/Pretendard-SemiBold.woff2") format("woff2"),
    url("./fonts/Pretendard-SemiBold.woff") format("woff"),
    url("./fonts/Pretendard-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  src:
    local("Pretendard Bold"),
    local("Pretendard-Bold"),
    url("./fonts/Pretendard-Bold.woff2") format("woff2"),
    url("./fonts/Pretendard-Bold.woff") format("woff"),
    url("./fonts/Pretendard-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 800;
  src:
    local("Pretendard ExtraBold"),
    local("Pretendard-ExtraBold"),
    url("./fonts/Pretendard-ExtraBold.woff2") format("woff2"),
    url("./fonts/Pretendard-ExtraBold.woff") format("woff"),
    url("./fonts/Pretendard-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 900;
  src:
    local("Pretendard Black"),
    local("Pretendard-Black"),
    url("./fonts/Pretendard-Black.woff2") format("woff2"),
    url("./fonts/Pretendard-Black.woff") format("woff"),
    url("./fonts/Pretendard-Black.ttf") format("truetype");
}

body {
  font-family: "Pretendard", sans-serif;
}

.ant-typography {
  font-family: Pretendard, Arial, sans-serif;
}

.anticon {
  margin-top: auto;
  margin-bottom: auto;
}

.post-content-wrapper {
  line-height: 1.5;
  color: black;
  word-wrap: break-word;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  & h1 {
    font-size: 2em;
  }

  & h2 {
    font-size: 1.5em;
  }

  & h3 {
    font-size: 1.17em;
  }

  & h4 {
    font-size: 1em;
  }

  & h5 {
    font-size: 0.83em;
  }

  & h6 {
    font-size: 0.67em;
  }

  & p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  & b,
  strong {
    font-weight: bold;
  }

  & i,
  em {
    font-style: italic;
  }

  & ul {
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 40px;
  }

  & ol {
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 40px;
  }

  & li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  & blockquote {
    margin: 1em 40px;
    padding-left: 15px;
    border-left: 4px solid #ccc;
  }

  & code {
    font-family: monospace;
    padding: 2px 4px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
  }

  & pre {
    font-family: monospace;
    margin: 1em 0;
    padding: 1em;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    overflow: auto;
  }

  & a {
    color: blue;
    text-decoration: underline;
  }

  & a:hover {
    text-decoration: none;
  }

  & table {
    width: 100%;
    border-collapse: collapse;
    margin: 1em 0;
  }

  & th,
  & td {
    border: 1px solid #ccc;
    padding: 8px;
  }

  & th {
    font-weight: bold;
    background-color: #f5f5f5;
  }

  & img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  & hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
  }
}

.ant-btn-primary {
  @apply bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-200;
}
